// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

// normalizers
import estimateParseData from "./parseData";

/**
 * Parse la réponse API d'estimate.
 */
const pricingEstimateParseData = ({ rawResponse }) => {
  const data = get(rawResponse, "data", {});

  return {
    data: Object.keys(data).reduce((rooms, roomReference) => {
      rooms[roomReference] = estimateParseData({
        rawResponseData: data[roomReference],
      });

      return rooms;
    }, {}),
  };
};

export default pricingEstimateParseData;
