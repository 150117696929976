// libraries
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

// constants
import { apiRouter } from "@cloudspire/legacy-shared/src/constants/router";
import { SLIDE_ALIAS_VALUE_HOME } from "../constants/slide";

// normalizers
import { slideParseSearch } from "../normalizers/slide";

export function slideHome() {
  return {
    key: generateUri({
      router: apiRouter,
      name: "Api.Slides.Search",
      query: {
        alias: SLIDE_ALIAS_VALUE_HOME,
        sorts: "position_asc",
      },
    }),
    normalizer: slideParseSearch,
  };
}
