// dependencies
import React from "react";
import PropTypes from "prop-types";
import { View, StyleSheet } from "react-native";
import { useIntl } from "react-intl";

// libraries
import { emToPx } from "../../libraries";

// hocs
import withMedia from "../../hocs/withMedia";

// components
import BaseComponent from "../BaseComponent";
import UIContainer from "../UIContainer";
import Carousel from "../Carousel";
import Image from "../Image";
import Text from "../Text";
import Heading from "../Heading";
import Button from "../Button2";
import Stack from "../Stack";

import { Row, Col } from "../Grid";

const styles = StyleSheet.create({
  homepageSlider: {
    position: "relative",
  },
  homepageSlideWrapper: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    paddingRight: "1em",
    paddingLeft: "1em",
    justifyContent: "center",
    pointerEvents: "none",
  },
  col: {
    height: "100%",
    pointerEvents: "all",
  },
  homepageSlide: {
    position: "relative",
  },
  heading: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 2,
    paddingLeft: "1em",
    paddingRight: "1em",
    justifyContent: "center",
  },
  headingWrapper: {
    textAlign: "center",
    marginTop: emToPx(3.5),
  },
  headingText__level2: {
    fontSize: emToPx(3),
    color: "#ffffff",
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 4,
    textShadowColor: "#3a485f",
    fontWeight: 500,
  },
  headingText__level1Responsive: {
    fontSize: emToPx(1.5),
  },
  headingText__level3: {
    fontSize: emToPx(2),
    color: "#ffffff",
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 4,
    textShadowColor: "#3a485f",
    marginTop: "20px",
    fontWeight: 500,
  },
  headingText__level2Responsive: {
    fontSize: emToPx(1),
  },
  flexEmbed: {
    height: "calc(100vh - 80rem)",
    minHeight: emToPx(40),
  },
  flexEmbed__responsive: {
    minHeight: emToPx(20),
  },
  title: {
    fontSize: emToPx(3.125),
    marginBottom: emToPx(7),
    color: "#ffffff",
    textShadowOffset: { width: 0, height: 2 },
    textShadowRadius: 4,
    textShadowColor: "#000",
    maxHeight: emToPx(8),
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontWeight: "bold",
  },
  title__responsive: {
    fontSize: emToPx(1.5),
    maxHeight: emToPx(9.0625),
    WebkitLineClamp: 5,
    textAlign: "center",
  },
  titleWrapper: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 2,
    top: emToPx(10),
    left: emToPx(1),
    right: emToPx(1),
  },
  form: {
    zIndex: 3,
  },
});

function HomepageSlider(props) {
  const { slideList = [], form, title, matches } = props;

  const intl = useIntl();

  return (
    <BaseComponent childrenStyle={styles.homepageSlider}>
      {matches && (
        <View style={styles.homepageSlideWrapper}>
          <UIContainer>
            <Row breakpointList={{ all: Infinity }} col={{ all: 7 }} gutter={1}>
              <Col span={3} offset={4} style={styles.col}>
                {form}
              </Col>
            </Row>
          </UIContainer>
        </View>
      )}
      {slideList.length > 0 ? (
        <Carousel autoplayOptions={{ delay: 4000 }}>
          {slideList.map((slide, index) => {
            return (
              <View key={index} style={styles.homepageSlide}>
                <View style={styles.heading}>
                  <Row
                    breakpointList={{ all: Infinity }}
                    col={{ all: matches ? 7 : 1 }}
                    gutter={1}
                  >
                    <Col span={matches ? 4 : 1}>
                      <Text style={styles.headingWrapper}>
                        <View>
                          <Heading
                            level={2}
                            textStyle={[
                              styles.headingText__level2,
                              !matches && styles.headingText__level1Responsive,
                            ]}
                          >
                            {slide.title}
                          </Heading>

                          <Heading
                            level={3}
                            textStyle={[
                              styles.headingText__level3,
                              !matches && styles.headingText__level2Responsive,
                            ]}
                          >
                            {slide.subtitle}
                          </Heading>

                          {slide.target && (
                            <>
                              <Stack marginTop={1} />

                              <View style={{ alignSelf: "center" }}>
                                <Button
                                  to={slide.target}
                                  appearance="primary"
                                  label={intl.formatMessage({
                                    defaultMessage: "Découvrir",
                                  })}
                                />
                              </View>
                            </>
                          )}
                        </View>
                      </Text>
                    </Col>
                  </Row>
                </View>
                <Image
                  key={index}
                  flexEmbedStyle={[
                    styles.flexEmbed,
                    !matches && styles.flexEmbed__responsive,
                  ]}
                  resizeMode="cover"
                  source={slide.coverUrls.large}
                />
              </View>
            );
          })}
        </Carousel>
      ) : (
        <div style={{ height: "calc(100vh - 8rem)", minHeight: "21.875rem" }} />
      )}
      {!matches && <View style={styles.form}>{form}</View>}
    </BaseComponent>
  );
}

HomepageSlider.propTypes = {
  slideList: PropTypes.array,
  form: PropTypes.any,
  title: PropTypes.string,
};

export default withMedia({ query: { minWidth: 900 } })(HomepageSlider);
