// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

type PricingEstimateParseDataReturnType = {
  reference: string;
  oldAmount: number;
  amount: number;
  discount?: {
    amount: number;
    rate: number;
  };
  state: keyof typeof import("@cloudspire/legacy-resources/src/constants/pricing")["PRICING_STATES"];
};

const pricingEstimateParseData = ({
  rawResponseData,
}): PricingEstimateParseDataReturnType => {
  const discounts = get(rawResponseData, "pricing.discounts");

  const amount = get(rawResponseData, "pricing.amount");

  const discountAmount = get(discounts, "periods");

  const discountRate = discountAmount
    ? Math.round((discountAmount * 100) / amount) / 100
    : undefined;

  return {
    reference: get(rawResponseData, "reference"),
    amount: discountRate ? amount - discountAmount : amount,
    oldAmount: amount,
    discount: discounts
      ? {
          amount: discountAmount,
          rate: discountRate,
        }
      : undefined,
    state: get(rawResponseData, "state"),
  };
};

export default pricingEstimateParseData;
